import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
})
export class LicenseComponent  {

  @Input() title: string;
  @Input() content: string;
  @Input() accept: string;

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {

  }

  
  setLicenseAgreementData(value) {
    Preferences.set({key: 'licenseAgreement', value: JSON.stringify(value) });
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
    this.setLicenseAgreementData("open");
  }

}
