import { Component, ViewChild } from '@angular/core';
import { DataService, Message } from '../services/data.service';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import {AlertController, IonSlides, LoadingController, ModalController,ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { filter } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { LicenseComponent } from '../license-agreement/license.component';
import { AnalyticsService } from '../analytics.services';
import { WarrantyRegistrationComponent } from '../warranty-registration/warranty-registration.component'
import { constants } from '../constants';
@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {
  @ViewChild('slides', { static: false }) slides: IonSlides;
  hre: any = "default";
  mobileUiText: any = {};
  headerText;
  deviceLang;
  deviceLangCode;
  current = '';
  languageDefault;
  languageDropdown;
  countryDropdown;
  defaultLanguage;
  prodList: any = [];
  bannerList: any = [];
  defaultCountry;
  countryId;
  actualImage;
  changeBackgroundCounter = 0;
  bannerCaption;
  bannerText;
  enableBanner = false;
  enableProdList = false;
  langChanged = false;
  isXs$;
  isMobile;
  isXsDevice;
  webBg;
  productscat;
  isIos;
  LicenseAgreement = false;
  Platform = Capacitor.getPlatform();
  loading = this.loadingController.create({
    cssClass: 'my-custom-class',
     message: 'Please wait while syncing data from server...',
    showBackdrop:true,
    id: "homeloading"
  });
  alert =  this.alertController.create(
    {
      header: 'New Feature Available',
      message: 'Register your Danfoss Drives to apply for a 12-month warranty extension.',
      backdropDismiss: false,
buttons:[
   {
   text: 'Click here to register',
   cssClass:'alert-button' ,
   handler: async () =>    {
    this.registerClick();
    }
   },{
    text: 'Cancel',
    role: 'cancel',
    cssClass: 'alert-button',
    handler: () => {
      console.log('Cancel clicked');
    }
   }, 
  {
    text: 'Dont Show Again',
    cssClass:'alert-button',
    handler: () => {
      this.data.setObject("dontShowAgain","true");      
    }
  }
],cssClass: 'custom-alert'
    });
  constructor(public data: DataService, private readonly router: Router,
    private readonly inAppBrowser: InAppBrowser,
    private readonly mediaObserver: MediaObserver,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
   public analyticsService: AnalyticsService,
   public alertController: AlertController,
   public toastController:ToastController
  ) {
    this.analyticsService.setScreenName("/HomeScreen");
  }
  async presentLoading() {
    (await this.loading).present();
     this.data.activeLoaders.push(await this.loading);
  }
  ionViewWillEnter() {
    if (this.Platform !== 'web') {
      if (this.slides) {
        this.slides.startAutoplay();
      }
    }
  }

  async presentLicenseModal(title, content, accept) {
    
    const modal = await this.modalCtrl.create({
      component: LicenseComponent,
      componentProps: {
        'title': title,
        'content': content,
        'accept': accept
      },
      backdropDismiss:false

    });    
    return await modal.present();
  }
  public ionViewWillLeave() {
    if (this.Platform !== 'web') {
      this.slides.stopAutoplay();
    }

  }


  ngOnInit() {
    if(this.Platform === 'ios'){
      this.isIos = true;
    }
    if ((this.Platform === 'android') || (this.Platform === 'ios')) {
      this.isMobile = true;
     
    }else {
      this.isMobile = false;  
      this.getLicenseAgreementData();    
    }

    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
    if (isPushNotificationsAvailable) {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {

      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {

      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {

      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {

      }
    );
    }
    this.data.getEmittedValue().subscribe(async item => {
      this.langChanged = true;
      this.router.navigateByUrl('/home');
      await this.getMobileUiData();
    });
    this.data.getEmittedcountryName().subscribe(countryName => {
      this.langChanged = true;
      this.getMobileUiData();
      this.router.navigateByUrl('/home');
    });
    this.getMobileUiData();

  }
 

  async getLicenseAgreementData() {
    const { value } = (await Preferences.get({ key: 'licenseAgreement' }));
     const data = JSON.parse(value);
     if(data == "open"){
      this.LicenseAgreement = true;
     } else if(data === "initiated") {
       this.LicenseAgreement = true;
     }else if(data === null) {
       Preferences.set({key: 'licenseAgreement', value: JSON.stringify('initiated') });
       this.LicenseAgreement = false;
     }
}

  async getMobileUiData() {
    this.data.getObject('defaultLangCode').then((defLangCode: any) => {
      this.data.deviceLangCode = defLangCode.value;
      this.deviceLangCode = this.data.deviceLangCode;
      this.data.getObject('defaultLanguage').then((defLang: any) => {
        this.data.defaultLanguage = defLang.value;
        this.defaultLanguage = this.data.defaultLanguage;
      })
      this.data.getObject('defaultCountry').then(defaultCountry => {
        this.defaultCountry = defaultCountry.value;
        this.data.defaultCountry = this.defaultCountry;
      })

      this.data.getObject('bannerList').then((bannerList: any) => {
        this.data.bannerList = JSON.parse(bannerList.value);
        const banerList = this.data.bannerList;
        if (this.deviceLangCode === 'en') {
          this.bannerList = banerList.filter(item => {
            return item.banner_type === 'MobileApp'
          });
          this.webBg= banerList.filter(item => {
            return item.banner_type === 'WebApp'
          });
        } else {
          this.bannerList = banerList;
        }

        if (this.bannerList) {
          this.enableBanner = true;
        }

      })

      this.data.getObject('mobileUiText').then(async mobileText => {
        this.data.mobileUiText = JSON.parse(mobileText.value);
        this.mobileUiText = this.data.mobileUiText;
        this.data.settings = this.mobileUiText?.id_7;
        this.data.downloads = this.mobileUiText?.id_81;
        this.data.home = this.mobileUiText.id_33;
        this.data.warrantyform= this.mobileUiText.id_82;
      const dontShowAgain = (await this.data.getObject("dontShowAgain")).value;
        if ((this.Platform === 'android') || (this.Platform === 'ios')) {
          // Nothing
          if(this.data.defaultCountry  ==="NAM" && !dontShowAgain && dontShowAgain !== 'true' )
          {                      
            (await this.alert).present();
          }
        } else {
          if(this.data.defaultCountry  ==="NAM" && !dontShowAgain && dontShowAgain !== 'true' )
          {                    
            (await this.alert).present();
          }
          if(!this.LicenseAgreement){
            const { value } = (await Preferences.get({ key: 'licenseAgreement' }));
            const data = JSON.parse(value);
            if(data == null || data == 'initiated') {
            this.presentLicenseModal(this.mobileUiText.id_71,this.mobileUiText.id_72, this.mobileUiText.id_73);
            }
          }
          
        }
      })
      this.data.getObject('languageDropdown').then(languageDropdown => {
        this.data.languageDropdown = JSON.parse(languageDropdown.value);
      })
      this.data.getObject('countryDropdown').then(countryDropdown => {
        this.data.countryDropdown = JSON.parse(countryDropdown.value);
        const countryDdown = JSON.parse(countryDropdown.value);

        const defCountryId = countryDdown.filter(item => {
          return item.value === this.defaultCountry
        });

        this.countryId = defCountryId[0]?.id;
        this.data.keyValSetObject('defaultCountryCode', defCountryId[0]?.id).then(data => {
          this.data.defaultCountryId = defCountryId[0]?.id;
        })
      })


      this.data.getObject('listItem').then(prodList => {
        this.data.productList = JSON.parse(prodList.value);
        this.prodList = this.data.productList;

        if (this.prodList) {
          this.enableProdList = true;
          this.productListFunc();
        }
        


      })
    })
  }
  productListFunc() {
    this.prodList.forEach((product, index) => {
      const id = (product.unique_id).toString();
      if (this.data.networkStatus) {
        this.data.productId = id;
        this.listDataInsert(id, product,index);
      }
    });
  }

  listDataInsert(id, product,index) {
    if(this.data.appInstall){
        if(this.isMobile && index == 0){
        setTimeout(() => {
         this.presentLoading();
        },100)
        }
        this.data.mobileCommonPrioritizeListApi(this.deviceLangCode, this.countryId, product.unique_id, 0).then(categ => {
        !this.data.mobileCommonPriorListApi && !this.langChanged ?
          this.data.keyValSetObject(id, categ.data).then(res => {this.catlistfunc(id)}) :
          this.data.setObject(id, categ.data).then(res => {this.catlistfunc(id)});
      })
    }
  }

  //Navigate to List Products
  productList(id: string, name, type, item, imageIp) {
    const val = JSON.stringify(id)
    const itemIp = JSON.stringify(item);
    const url = 'home/listCategory';
    this.router.navigate([url], { queryParams: { item: itemIp, title: name, type: type, id: val, change: this.langChanged, image: imageIp, cat: true } });
  }
  slideOpts = {
    effect: 'fade',
    autoplay: true,
    initialSlide: 2,
    speed: 200,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true
    }
  };

  //Slider window Read more Button
  readMoreFunc(url) {
    const options: InAppBrowserOptions = {
      location: 'yes',
      toolbarcolor: '#B6000F',
      navigationbuttoncolor: 'white',
      closebuttoncaption: 'close',
      closebuttoncolor: 'white',
      hideurlbar: 'yes',
      toolbar: 'yes',
      useWideViewPort: 'yes',
      fullscreen: 'yes'
    }
    if(this.data.networkStatus){
    this.inAppBrowser.create(url, '_blank', options);
    }else{
      this.presentToast();
    }
  }

  public media$ = this.mediaObserver.asObservable().pipe(filter(
    (change: MediaChange[]) => change[0].mqAlias === 'xs')).subscribe(() => {
      if ((this.Platform === 'android') || (this.Platform === 'ios')) {
        this.isXs$ = true;
      } else {
        this.isXs$ = true;
        this.isXsDevice=true;
      }
    });

    public media1$ = this.mediaObserver.asObservable().pipe(filter(
      (change: MediaChange[]) =>change[0].mqAlias !== 'xs')).subscribe(() => {          
          this.isXs$ = false;
          this.isXsDevice=false;        
      });

  public getToolbarColor(): string {
    if ((!this.isXs$) &&(!((this.Platform === 'android') || (this.Platform === 'ios')))) {
      return 'light';
    }
    else{
    return 'danfoss-red';
    }
  }  
    
  dismissLoading = async () => {
     this.data.activeLoaders.forEach(async (loader) => {
      if (loader) {      
        await loader.dismiss();     
      }
    });     
  }
  catlistfunc(id){
    this.data.getObject(id).then(catlist => {
      this.productscat = JSON.parse(catlist.value);
      if (this.data.networkStatus && this.productscat.length !== 0) {
        this.prodCatfunc({id:id,change:this.langChanged});
      }else{
        this.dismissLoading();
      }
    })
  }
  prodCatfunc(dataip) {
    for (const product of this.productscat) {
      const id = (product.unique_id).toString();
      this.apidatafunc(product, dataip, id);
    }
  }
  apidatafunc(product, dataip, id) {
    // eslint-disable-next-line max-len
    this.data.mobileCommonPrioritizeListApi(this.data.deviceLangCode, this.data.defaultCountryId, dataip.id, product.unique_id).then(prod => {
      if (!this.data.mobileCommonPriorListApi && dataip.change === false) {
        this.data.keyValSetObject(id, prod.data).then(data=>{
          if(data === 'exists'){
             setTimeout(() => {
            this.dismissLoading();
             }, 5)
          }else if(data === 'notExists'){
             //this.presentLoading();
            if (this.productscat.indexOf(product) === Math.round((this.productscat.length/2) - 1)) {
              setTimeout(() => {
                this.dismissLoading();
              }, 4)
            }
          }
        })
      } else {
        // this.presentLoading();
        this.data.setObject(id, prod.data).then(data => {
          if (this.productscat.indexOf(product) === Math.round((this.productscat.length/2) - 1)) {
            this.syncTableDateUpdate();
          }
        })
      }
    })
  }
  syncTableDateUpdate() {
    const currentDate = new Date();
    if (this.data.tableList.length > 0) {
      this.dismissLoading();
      this.data.tableList.forEach(table => {
        this.data.setObject(`${table}_table`, currentDate.toString());
      })
    }
    else {
      this.dismissLoading();
    }
  }
   async presentToast() {
    const toast = await this.toastController.create({
      message:  this.mobileUiText.id_35 ?? "Network Went Offline!" ,
      duration: 2000,
      cssClass:  constants.isNetworkOfflineCss,
    });
    toast.present();
  }
  async registerClick() {
    if(this.data.isMobile)
    {
      const modal = await this.modalCtrl.create({ component: WarrantyRegistrationComponent });
      await modal.present();
    }
    else{
      const url = 'home/Warranty';
      this.router.navigate([url]);
    }
  }
}
