import { Injectable } from '@angular/core';

import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Device } from '@capacitor/device';


// import { Plugins } from "@capacitor/core";
import { environment } from 'src/environments/environment';
// const { FirebaseAnalytics, Device } = Plugins;


@Injectable({
    providedIn: 'root'
  })
  export class AnalyticsService {

    analyticsEnabled = true;

    constructor( private router: Router) {
        this.initFb();
        this.router.events.pipe(
          filter((e: RouterEvent) => e instanceof NavigationEnd),
        ).subscribe((e: RouterEvent) => {
         // this.setScreenName(e.url);
        });
      }

      async initFb() {
        if ((await Device.getInfo()).platform == 'web') {
          const app =  FirebaseAnalytics.initializeFirebase(environment.firebaseConfig).then(async data=>{
          await this.setUser();
        })
        }
      }

      async setUser() {
        // Use Firebase Auth uid
        const deviceId = await Device.getId();
        await FirebaseAnalytics.setUserId({
          userId: deviceId.identifier,
        }).then(data=>{}).catch(err=>{          
            console.log("setuser catch==",err );
          
        })
      }

      setProperty(val) {
       FirebaseAnalytics.setUserProperty({
          name: "Screen-name",
          value: val,
        });
      }

     logEvent(screenName) {
        FirebaseAnalytics.logEvent({
          name: "Screen_View",
          params: {
           ScreenName:screenName
          }
        }).then(datalog=>{}).catch(err=>{console.log("log err=",err ); })
      }

      setScreenName(screenName) {
       FirebaseAnalytics.setScreenName({
          screenName: screenName,
          nameOverride: screenName
       });
       this.logEvent(screenName);
       this.setProperty(screenName);
      }



      toggleAnalytics() {
        this.analyticsEnabled = !this.analyticsEnabled;
        // FirebaseAnalytics.setCollectionEnabled({
        //   enabled: this.analyticsEnabled,
        // });    
      }

  }