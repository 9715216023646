import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { DataService } from '../../services/data.service';
@Component({
  selector: 'app-industry-page',
  templateUrl: './industry-page.component.html',
  styleUrls: ['./industry-page.component.scss'],
})
export class IndustryPageComponent implements OnInit {
  input;
  title;
  headerContent:any={};
  productscat;
  appText;
  inputQuery;
  applicationList;
  defaultLanguage;
  deviceLangCode;
  appImage='';
  mobileUiText;
  isEnable=false;
  constructor(private readonly router:Router,private readonly activatedRoute:ActivatedRoute,
    private dataService:DataService) { }

    isMobile;
    Platform = Capacitor.getPlatform();
    ngOnInit() {
      if ((this.Platform === 'android') || (this.Platform === 'ios')) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    this.dataService.getObject('defaultLangCode').then((defLangCode:any)=>{
      this.dataService.deviceLangCode= defLangCode.value;
      this.deviceLangCode=this.dataService.deviceLangCode;
      this.dataService.getObject('defaultLanguage').then((defLang:any)=>{
      this.dataService.defaultLanguage= defLang.value;
      this.defaultLanguage=this.dataService.defaultLanguage;

     }) 
     this.dataService.getObject('mobileUiText').then(mobileText=>{
      this.dataService.mobileUiText= JSON.parse(mobileText.value);   
         this.mobileUiText=this.dataService.mobileUiText;     
         this.dataService.settings=this.mobileUiText?.id_7;
         this.dataService.downloads = this.mobileUiText?.id_81;
         this.dataService.home=this.mobileUiText.id_33;
     })
     
     this.dataService.getObject('listItem').then(prodList=>{
      this.dataService.productList=JSON.parse(prodList.value);        
     });
     this.activatedRoute.queryParams.subscribe(dataip=>{
      this.inputQuery=dataip;
      this.title=dataip.industryName;
        this.appText=dataip.text;
        this.headerContent={        
            title:this.title,back:'this.previouspage',currentPage:'this.current'
           };
       
        this.dataService.getObject(this.inputQuery.catid).then(details=>{
        const contentip=JSON.parse(details.value);
        if(contentip){
         const contentarray=contentip.filter(item=>{
            return item.unique_id ===  Number(this.inputQuery.id) 
          })
           
        this.applicationList=contentarray[0].application_list;  
          
        this.appImage=contentarray[0].image;     
   
        this.isEnable=true;
        }else{
          this.router.navigate(['']);
        }
       })
     })
    })
  }
}
