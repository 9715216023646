import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators,FormArray} from '@angular/forms';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ModalController, ToastController, AlertController, LoadingController } from '@ionic/angular';
import { constants } from '../constants';
import { DataService } from '../services/data.service';
@Component({
  selector: 'app-warranty-registration',
  templateUrl: './warranty-registration.component.html',
  styleUrls: ['./warranty-registration.component.scss'],
})
export class WarrantyRegistrationComponent implements OnInit {
  formData ={}
  registerForm: FormGroup;
  SignChangeBool: boolean = false;
  yesClicked: boolean;
  noClicked: boolean;
  isAgree: boolean;
  Platform;
  isMobile;
  mobileUiText: any;
  isMobileUitext = false;
  errorFields =

    [
      {
        name: "drive_serial_number", value: "", isActive: false, count: 0
      },
      {
        name: "part_number", value: "", isActive: false, count: 0
      },
      {
        name: "another_drive_serial_number", value: "", isActive: false, count: 0
      },
      {
        name: "another_part_number", value: "", isActive: false, count: 0
      },
    ]
  constructor(public formBuilder: FormBuilder, private readonly router: Router, public modalController: ModalController, public dataService: DataService, public toastController: ToastController, 
	public loadingController:  LoadingController) {
    this.yesClicked = false;
    this.noClicked = false;
    this.isAgree = false;
    this.registerForm = this.formBuilder.group({
      driveSerialNumber: ['', Validators.required],
      partNumber: ['', Validators.required],
      company: ['', [Validators.required, Validators.maxLength(100)]],
      contactName: ['', [Validators.required,Validators.maxLength(100)]],
      personName: [''],
      email: ['', [Validators.required, Validators.pattern("^(([ A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z  ]{2,4}(,|$) ?))*$")]],
      phone: ['', [Validators.required, Validators.pattern('^[0-9 +\-]+$')]],
      zipCode: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9]+$')]],
      address: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_ ]*$')]],
      city: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_ ]*$')]],
      state: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_ ]*$')]],
      countrySelection: ['', Validators.required],
      radioSelection: ['', Validators.required],
      AnotherDriveSerialNumberList: this.formBuilder.array([this.initField()]),
      AnotherPartNumberList: this.formBuilder.array([this.initField()]),
      isAgree: [false, Validators.requiredTrue],
      isSubcribe: [false,],
      comments: ['', [Validators.maxLength(250)]],
    });
  }
    ionViewWillEnter(){
      this.registerForm.reset();
    }
  conditionalRequired(visible: boolean) {
    return (control) => {
      if (visible) {
        return Validators.required(control);
      } else {
        return null;
      }
    };
  }
  checkValue(e: any) {
    const hiddenField = this.registerForm.get('AnotherDriveSerialNumberList');
    const hiddenField2 = this.registerForm.get('AnotherPartNumberList'); 
    if (e.detail.value === "true") {
      this.yesClicked = true;
      this.noClicked = true;
      this.registerForm.get('AnotherPartNumberList').enable();
      this.registerForm.get('AnotherDriveSerialNumberList').enable();
      // hiddenField.setValidators(this.conditionalRequired(true));
      // hiddenField2.setValidators(this.conditionalRequired(true));     
    }
    else {

      hiddenField.clearValidators();
      hiddenField2.clearValidators();
        this.registerForm.get('AnotherPartNumberList').disable();
      this.registerForm.get('AnotherDriveSerialNumberList').disable();
      // hiddenField.setValidators(this.conditionalRequired(false));
      // hiddenField2.setValidators(this.conditionalRequired(false));
      this.yesClicked = false;
      this.noClicked = true;    
    }
    hiddenField.updateValueAndValidity();
    hiddenField2.updateValueAndValidity();
  }
  closeModal() {
    if ((this.Platform === 'android') || (this.Platform === 'ios')) {
      this.isMobile = true;
      this.modalController.dismiss();
    }
    else {
      this.router.navigateByUrl('/home');
    }
  }
  onSubmit() {
   
    if (this.registerForm.valid) {
      if(this.dataService.networkStatus || this.Platform === 'web')
      {
      this.formData = {
        drive_serial_number: this.registerForm.get('driveSerialNumber').value,
        part_number: this.registerForm.get('partNumber').value,
        company_name: this.registerForm.get('company').value,
        email: this.registerForm.get('email').value,
        contact_person: this.registerForm.get('contactName').value,
        mobile_number: this.registerForm.get('phone').value,
        postal_code: this.registerForm.get('zipCode').value,
        like_to_register_another_drive: this.registerForm.get('radioSelection').value === "true",
        is_agree: this.registerForm.get('isAgree').value,
        // another_drive_serial_number: this.registerForm.get('AnotherdriveSerialNumber').value,
        // another_part_number: this.registerForm.get('AnotherpartNumber').value,
        another_drive_serial_number: this.registerForm.get('AnotherDriveSerialNumberList').value,
        another_part_number: this.registerForm.get('AnotherPartNumberList').value,
        is_subscribe: this.registerForm.get('isSubcribe').value === "true",
        region_name: "",
        comments: this.registerForm.get('comments').value,
        country_name: this.registerForm.get('countrySelection').value,
        person_name:this.registerForm.get('personName').value,
        address:this.registerForm.get('address').value,
        city:this.registerForm.get('city').value,
        state:this.registerForm.get('state').value
      }

      this.dataService.warrantyCreation(this.formData).then(async res => {
        const loading = await this.loadingController.create({
          message: 'Please wait',      
        });
        await loading.present();
        if (res.status) {
          const toast = await this.toastController.create({
            message: res.message,
            duration: 3000,
            cssClass: constants.isNetworkOfflineCss,
          });
          this.registerForm.reset();
           this.errorFields = [
            {
              name: "drive_serial_number", value: "", isActive: false, count: 0
            },
            {
              name: "part_number", value: "", isActive: false, count: 0
            },
            {
              name: "another_drive_serial_number", value: "", isActive: false, count: 0
            },
            {
              name: "another_part_number", value: "", isActive: false, count: 0
            },
          ]
          toast.present();
          await loading.dismiss();
          
        }
        else {
          this.errorFields = [
            {
              name: "drive_serial_number", value: "", isActive: false, count: 0
            },
            {
              name: "part_number", value: "", isActive: false, count: 0
            },
            {
              name: "another_drive_serial_number", value: "", isActive: false, count: 0
            },
            {
              name: "another_part_number", value: "", isActive: false, count: 0
            },
          ]
          for (const errorField of this.errorFields) {
            res.data.some((obj) => obj.name === errorField.name ? this.compareObjects(obj, errorField) : this.clearRecord(errorField));
          }
          const toast = await this.toastController.create({
            message: res.message,
            duration: 3000,
            cssClass: constants.isNetworkOfflineCss,
          });
          toast.present();
          await loading.dismiss();
        }

      })  
      }else{
        this.presentToast();
      }
    }
     else {
       this.ErrorToast();
      this.markFormGroupTouched(this.registerForm);
    }
  }
  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  customPopoverOptions: any = {
    header: 'Select language',
    cssClass: 'custom-popover',
    width: '100%',
  };
  ngOnInit() {
      this.Platform = Capacitor.getPlatform();
      this.dataService.getObject('mobileUiText').then(mobileText => {
      this.dataService.mobileUiText = JSON.parse(mobileText.value);
      this.mobileUiText = JSON.parse(mobileText.value);
      this.isMobileUitext = true;
      if(!this.dataService.networkStatus)
      {
       this.presentToast();
      }
    })
  }
  async presentToast() {
    const toast = await this.toastController.create({
      message:  this.mobileUiText.id_46 ?? "Network Offline! Please enable internet" ,
      duration: 2000,
      cssClass:  constants.isNetworkOfflineCss,
    });

    toast.present();

  }
    async ErrorToast() {
    const toast = await this.toastController.create({
      message:  this.mobileUiText.id_126 ?? "Kindly Fill Mandatory Fields" ,
      duration: 4000,
      cssClass:  constants.isNetworkOfflineCss,
    });

    toast.present();

  }

  compareObjects(obj1, obj2) {
    obj2.value = obj1.error;
    obj2.isActive = true;
  }
  clearRecord(obj) {
    if (!obj.isActive) {
      obj.value = "";
      obj.isActive = false;
    }
  }
   errorClear(obj) {
    this.errorFields[obj].isActive=false;
    this.errorFields[obj].value="";
  }
  get SerialNumerfieldsArray(): FormArray {
    
    return this.registerForm.get('AnotherDriveSerialNumberList') as FormArray;
  }
  get PartNumberfieldsArray(): FormArray {
    
    return this.registerForm.get('AnotherPartNumberList') as FormArray;
  }

  initField() {
    console.log("init");
   // console.log("==1==",this.fieldsArray)
    const fieldControl = this.formBuilder.control({value:'',disabled:true},Validators.required);
  return fieldControl;
    
}

  addField(): void {
    const fieldControl = this.formBuilder.control({value:'',disabled:this.yesClicked?false:true},Validators.required);
    const fieldControlPart = this.formBuilder.control({value:'',disabled:this.yesClicked?false:true},Validators.required);
      this.SerialNumerfieldsArray.push(fieldControl);
      this.PartNumberfieldsArray.push(fieldControlPart);
    
  }
  removeInputField(i : number) : void
  {
    const control = <FormArray>this.registerForm.controls.AnotherDriveSerialNumberList;
    control.removeAt(i);
    const controlpart = <FormArray>this.registerForm.controls.AnotherPartNumberList;
      controlpart.removeAt(i); 
  }
}