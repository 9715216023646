import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { DataService } from '../services/data.service';
import { InAppBrowser, InAppBrowserEvent, InAppBrowserOptions, } from '@ionic-native/in-app-browser/ngx';
import { Capacitor } from '@capacitor/core';
import { File } from '@ionic-native/file/ngx';
import{PreviewAnyFile} from '@ionic-native/preview-any-file/ngx'; 

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss'],
})
export class DownloadsComponent implements OnInit {
  previouspage;
  current;
  title="Downloads";
  headerContent;
  fileList;
  isMobile;
  Platform = Capacitor.getPlatform();
  constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute,private dataService: DataService,
    private readonly inAppBrowser: InAppBrowser, private file: File, 
    private previewAnyFile:PreviewAnyFile,) { 
    this.isMobile= this.dataService.isMobile;
    this.activatedRoute.queryParams.subscribe(dataip => {
       
      this.title = dataip.title;  
      this.previouspage=dataip.back;        
      this.headerContent = {
        title: this.title, back: this.previouspage, currentPage: this.current,cat:true          };
    })
     this.getData();
  }
getData(){
  Filesystem.readdir({
       path: `PDF`,
   directory  : Directory.External
   }).then(readFile => {
     
     console.log("list cache----",readFile)
     this.fileList = readFile.files;

   })
}
  ngOnInit(){}
   async share(item){
        Share.share({
        title: '',
        text: '',
        url:item.uri,
        dialogTitle: '',
      }).then(sresult=>{
        console.log("sresult,",sresult);
      })
  }

  delete(item){
    Filesystem.deleteFile({
      path:`${'PDF'}/${item.name}`,
      directory:Directory.External,
    }).then( resdel=>{
     this.getData();
    })
  }

  view(item) {      
    if ((this.Platform === 'android') || (this.Platform === 'ios')) {      
       this.previewAnyFile.preview(item.uri).then(() => console.log('== File opened successfully ==')).catch(e => console.log(e));
     }  
  }
}
