import { Component, OnInit, Input, } from '@angular/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { Capacitor } from '@capacitor/core';


const cacheFolder = 'cacheImage';
@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit {
  imgName;
  Platform;
  isEnable = false;
  @Input() srcimage: any;
  constructor(public fTransfer: FileTransfer, public file: File) {
  

  }
  fileTransfer: FileTransferObject = this.fTransfer.create();

  ngOnInit() {
    this.Platform = Capacitor.getPlatform();
    if (this.Platform !== 'web') {
      const imgUrl: string = this.srcimage.image;
     
      const imgNamelist = imgUrl.split('/');
      const imagename = imgNamelist.pop();
      const imageid = imgNamelist.pop();
      this.imgName = `${imageid}_${imagename}`;
      if (this.srcimage.image && this.Platform !== 'web') {
        this.download(this.srcimage.image, this.imgName);
      }
    
    } else {
      this.isEnable = true;
    }
  }

  getImageFile(image, imageurl){
    if(this.Platform === 'ios'){
      return imageurl.image
    } else {
      return ('/_capacitor_file_/data/user/0/com.danfoss.DrivePortfolio/cache/'+`${cacheFolder}/${image}`)
    }
  }

  download(imageUrl, imgName) {

   
    Filesystem.readFile({
      path: `${cacheFolder}/${imgName}`,
      directory: Directory.Cache,
    }).then(readFile => {
      this.isEnable = true;

    }).catch(async e => {
      
      this.fileTransfer.download(encodeURI(imageUrl), `${this.file.cacheDirectory}/${cacheFolder}/${imgName}`).then((entry) => {
        if (entry) {
          this.isEnable = true;
        }
      }, (error) => {
        // handle error
        console.error('download error: ', error);
      });

    })
  }
}
