import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Preferences } from '@capacitor/preferences';
import { environment } from "../../environments/environment";
import { Injectable, Output, EventEmitter } from '@angular/core'
import { Observable } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';

const appJson = 'application/json';
export interface Message {
  fromName: string;
  subject: string;
  date: string;
  id: number;
  read: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public activeLoaders: HTMLIonLoadingElement[] = [];
  @Output() language: EventEmitter<any> = new EventEmitter();
  @Output() mailcount: EventEmitter<any> = new EventEmitter();
  @Output() countryChanged: EventEmitter<any> = new EventEmitter();
  @Output() isLocStorage: EventEmitter<any> = new EventEmitter();
  @Output() title: EventEmitter<any> = new EventEmitter();

  public mailCount = 0;
  mailContentArray = [];
  mobileUiText: any = {};
  countryList: any = {};
  keys = [];
  defaultLanguage: Promise<any>;
  deviceLangCode: any;
  defaultRegion: any;
  bannerList: any;
  languageDropdown: any;
  countryDropdown: any;
  defaultCountry: any;
  defaultCountryId: any;
  productList: any;
  networkStatus = false;
  settings: any;
  productId: any;
  appInstall = false;
  driveProinfoApi = false;
  productCategoryApi = false;
  industryDriveproListApi = false;
  industryListProductApi = false;
  mobileProdCatListApi = false;
  home: any;
  mobileCommonPriorListApi = false;
  tableList = [];
  SignatureStore;
  isMobile:boolean;
  downloads:any;
  loading:any;
  warrantyform:any;
  countryFullName;
  async SetSignatureData(): Promise<any> {
    await this.getObject("SignatureData").then((data) => {
      this.SignatureStore = JSON.parse(data.value);
      return this.SignatureStore;
    })

  }
  setMailCount(input) {

    if (input) {
      this.mailCount = this.mailCount + 1;
    } else {
      this.mailCount = 0;
    }

    this.mailcount.emit(this.mailCount);
  }
  getEmittedMailcount() {

    return this.mailcount;
  }
  setTitle(input) {


    this.title.emit(input);
  }
  getEmittedTitle() {

    return this.title;
  }
  change(lcode) {

    this.language.emit(lcode);
  }

  getEmittedValue() {

    return this.language;
  }
  countryChange(countryName) {

    this.countryChanged.emit(countryName);
  }

  getEmittedcountryName() {

    return this.countryChanged;
  }
  setLStorage(lsValue) {
    this.isLocStorage.emit(lsValue);

  }

  getEmittedisLocStorage() {
    return this.isLocStorage;
  }

  constructor(public httpClient: HttpClient,public loadingController : LoadingController, private readonly router: Router,) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': appJson,
      Accept: appJson,
    })
  };
  // public getMessages(): Message[] {
  //   return this.messages;
  // }

  // public getMessageById(id: number): Message {
  //   return this.messages[id];
  // }
  // MobileUiText api to get text for mobileui
  async mobileUiTextApi(deviceLangCode): Promise<any> {
    const input: any = { "language_code_list": [deviceLangCode] };

    return this.httpClient.post(`${environment.host}/mobileapp-mobileuitext-view-languagewise`, JSON.stringify(input),
      this.httpOptions).toPromise().then(async data => {
        return data;

      }).catch(error => {
        console.error('errormobileuitext', error);
      })
  }
  // mobileLanguageApi api to get data for Language dropdown
  async mobileLanguageApi(): Promise<any> {

    return this.httpClient.get(`${environment.host}/mobileapp-language-dropdown`,
      this.httpOptions).toPromise().then(async data => {
        return data;
      }).catch(error => {
        console.error('catch lang error', error);
      })
  }
  // mobileCountryApi api to get data for country dropdown
  async mobileCountryApi(langCode): Promise<any> {
    //  this.deviceLangCode
    return this.httpClient.get(`${environment.host}/mobileapp-region-dropdown/${langCode}/`,
      this.httpOptions).toPromise().then(data => {
        return data;
      }).catch(error => {
        console.error('catch country error', error);
      })
  }
  // mobileBannerApi api to get data for Bannerview(bannerlist,description,url)
  async mobileBannerApi(langCode): Promise<any> {

    return this.httpClient.get(`${environment.host}/mobileapp-banner-list/${langCode}/`,
      this.httpOptions).toPromise().then(data => {
        return data;
      }).catch(error => {
        console.error('catch banner error', error);
      })
  }
  //mobileProdListApi to get home screen product list
  public mobileProdListApi(langCode): Promise<any> {

    return this.httpClient.get(`${environment.host}/mobileapp-item-dropdown/${langCode}/`,
      this.httpOptions).toPromise().then(data => {

        return data;
      }).catch(error => {
        console.error('catch prodlist error', error);
      })
  }


  async emailVerifyApi(fromEmail): Promise<any> {

    return this.httpClient.post(`${environment.host}/verfiy-email`, fromEmail,
      this.httpOptions).toPromise().then(data => {
        return data;
      }).catch(error => {
        console.error('catch send mail error', error);
      })

  }

  //warrantyCreation: warranty registration form data sent to Backend
  async warrantyCreation(formData): Promise<any> {
    return this.httpClient.post(`${environment.host}/create-user`, formData,
      this.httpOptions).toPromise().then(data => {        
        return data;
      }).catch(error => {
        console.error('catch send mail error', error);
      })
  }
  //mobileSendMailApi to get an details of mobileDrivePro general data for infoIcon
  async mobileSendMailApi(mailData): Promise<any> {

    return this.httpClient.post(`${environment.host}/send-mail`, mailData,
      this.httpOptions).toPromise().then(data => {
        return data;
      }).catch(error => {
        console.error('catch send mail error', error);
      })

  }

  //mobileOfflineSyncApi to sync local Store data with the updated data.
  async mobileOfflineSyncApi(): Promise<any> {
    return this.httpClient.get(`${environment.host}/view-offline-sync`,
      this.httpOptions).toPromise().then(data => {
        return data;

      }).catch(error => {
        console.error('errorofflinesync', error);
      })
  }
  //appUpdateVersionCheck to sync local Store data with the updated data.
  async appUpdateVersionCheck(): Promise<any> {
    return this.httpClient.get(`${environment.host}/app-version-details`,
      this.httpOptions).toPromise().then(data => {

        return data;

      }).catch(error => {
        console.error('errorofflinesync', error);
      })

  }
  //mobileCommonPrioritizeListApi is common service to get data of all type of products.
  async mobileCommonPrioritizeListApi(lcode, ccode, pid, cid): Promise<any> {
    const postIp = { "language_code": lcode, "country_id": 0, "parent_id": pid, "child_id": cid, "region_id": ccode };
    return this.httpClient.post(`${environment.host}/mobile-item-priority`, postIp,
      this.httpOptions).toPromise().then(postresponsedata => {
        return postresponsedata;

      }).catch(error => {
        console.error('errorcommonprioritization', error);
      })

  }
 //pdf download
 async DownloadPdf(url): Promise<any> {
  return this.httpClient.get(`${url}`,{ headers: new HttpHeaders({
      "Content-Type":"text/html"	
    })  }).toPromise().then(postresponsedata => {
      console.log("DownloadPdf==",postresponsedata);
    }).catch(error => {
      console.error('errorDownloadPdf', error);
      return error;
    })

}

 //download .pdf file
 DownloadPdfFile(url): Observable<any>{
  try{
    let headers = new HttpHeaders();
    return this.httpClient.get(url,{ headers: headers,responseType: 'blob'});
  }catch(excep){
    console.log(" pdf error==",excep);
  }
}

//location APi for web
LocationAPi(latitude,longitude):Observable<any>{
  try {
    let url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;
    return this.httpClient.get(url); 
  } catch (error) {  console.log("Location Api for web=Error==",error);
  }
}
  async keyValSetObject(keyip, valueip): Promise<any> {
    let res;
    return Preferences.keys().then(async (data) => {
      this.keys = data.keys;
      if (!this.keys.includes(keyip)) {
        this.setObject(keyip, valueip);
        res = 'notExists'
        return res;
      } else {
        res = 'exists';
        return res;
      }
    });
  }

  // JSON "set" example
  async setObject(keyip, valueip) {
    let ipType = false;
    let keyType = false;
    if (typeof (valueip) == 'string') {
      ipType = true;
    }
    if (typeof (keyip) == 'string') {
      keyType = true;
    }
    const val = ipType ? valueip : JSON.stringify(valueip);
    const key = keyType ? keyip : JSON.stringify(keyip);
    Preferences.set({ key: key, value: val });
  }

  //  Get object from Local Preferences
  getObject(keyip) {
    return Preferences.get({ key: keyip });

  }
  //mobileAPi works in installation of App 
  async mobileAPi(deviceLangCode): Promise<any> {
    return new Promise((resolve, reject) => {


      this.mobileUiTextApi(deviceLangCode).then(apidata => {
        const uiText = apidata.data;
        this.mobileUiText = uiText[0].text;
        this.keyValSetObject('mobileUiText', uiText[0].text);
      })

      this.mobileLanguageApi().then((apidata) => {
        this.defaultLanguage = (apidata.data).filter((list) => {
          return list.code === deviceLangCode
        });
        const languagelist = apidata.data;
        this.keyValSetObject('defaultLanguage', languagelist[0].name);
        this.keyValSetObject('languageDropdown', apidata.data);
      })
      this.mobileCountryApi(deviceLangCode).then((apidata) => {
        this.countryList = apidata.data;
        this.keyValSetObject('countryDropdown', apidata.data);
      })
      this.mobileProdListApi(deviceLangCode).then((apidata) => {
        this.keyValSetObject('listItem', apidata.data).then(insdata => {
          this.mobileBannerApi(deviceLangCode).then((apidata) => {
            this.keyValSetObject('bannerList', apidata.data).then(insdata => {
              resolve(true);
            });
          })
        })
      })
      


    }).catch(e => {
      console.error('catch rest api', e);
    })
  }

    fetchAndInsert() {
    this.mobileUiTextApi(this.deviceLangCode).then((mobileText) => {
      this.setObject("mobileUiText", mobileText.data[0].text).then(data => {
        this.mobileUiText = mobileText.value;
      })
    })

    this.mobileProdListApi(this.deviceLangCode).then((apidata) => {
      this.deviceLangCode = this.deviceLangCode;
      this.setObject('listItem', apidata.data).then(data => {
        this.getObject('listItem').then(List => {
          this.productList = JSON.parse(List.value);         
          this.change(this.deviceLangCode);
        })
      })
    })

    this.mobileBannerApi(this.deviceLangCode).then((apidata) => {
      this.setObject(`bannerList"${this.deviceLangCode}`, apidata.data).then(data => {
      })
    })

    this.mobileCountryApi(this.deviceLangCode).then((apidata)=>{
      this.setObject(`countryDropdown`, apidata.data).then(data => {
        this.getObject('countryDropdown').then(countryDropdown => {
          this.countryDropdown = JSON.parse(countryDropdown.value);
          this.getObject('defaultCountry').then(defaultCountry => {
           
          const countryvalid = apidata.data.filter(
            item => { return item.id ===  this.defaultCountryId})
          
            if(countryvalid[0]){
              this.setObject('defaultCountry', countryvalid[0].value)
              this.countryChange(countryvalid[0].value)
            } else {
              this.setObject('defaultCountry', apidata.data[0].value);
              this.defaultCountry = apidata.data[0].value;
              this.setObject('defaultCountryCode', apidata.data[0]?.id)
                this.defaultCountryId = apidata.data[0]?.id;
                this.defaultCountry = apidata.data[0].value;
                this.countryChange(apidata.data[0].value)
            }
                   
          })
        })
      })

     
    })
  }
  //Sync function to get latest changes and offline sync
  mobileDataSyncInitFunc() {
    return new Promise((resolve, reject) => {
      try {
        const currentDate = new Date();

            this.getObject('defaultLangCode').then((defLangCode: any) => {
          this.deviceLangCode = defLangCode.value;
          this.mobileLanguageApi().then((apidata) => {
            this.setObject('languageDropdown', apidata.data).then(res => {
              this.getObject('languageDropdown').then(languageDropdown => {
                this.languageDropdown = JSON.parse(languageDropdown.value);
                let languagevalid = apidata.data.filter(i => i.value === defLangCode.value)[0]
                if(languagevalid){
                  this.setObject('defaultLangCode', defLangCode.value)
                  this.deviceLangCode = defLangCode.value
                  this.change(defLangCode.value)
                //  this.fetchAndInsert();
                } else {
                  this.setObject('defaultLangCode', apidata.data[0].code)
                  this.setObject('defaultLanguage', apidata.data[0].name)
                  this.defaultLanguage = apidata.data[0].name
                  this.deviceLangCode = apidata.data[0].code
                  this.change(apidata.data[0].code)
                //  this.fetchAndInsert();                  
                }
                
              })
            })
          });

          this.mobileCountryApi(this.deviceLangCode).then((apidata) => {
            this.setObject('countryDropdown', apidata.data).then(res => {
              this.getObject('countryDropdown').then(countryDropdown => {
                this.getObject('defaultCountry').then(defaultcountry=>{
                
                  let countryvalid = apidata.data.filter(i => i.value === defaultcountry.value)
                  if(countryvalid) {
                   this.setObject('defaultCountry', countryvalid[0].value)
                    this.defaultCountry = countryvalid[0].value;                      
                    this.setObject('defaultCountryCode',countryvalid[0].id )
                    this.defaultCountryId= countryvalid[0].id;               
                    this.countryChange(defaultcountry.value)
                  } else {
                    this.setObject('defaultCountry', apidata.data[0].value);
                      this.defaultCountry = apidata.data[0].value;
                      this.setObject('defaultCountryCode', apidata.data[0]?.id)
                        this.defaultCountryId = apidata.data[0]?.id;
                        this.defaultCountry = apidata.data[0].value;
                        this.countryChange(apidata.data[0].value)
                  }
                })
                
                this.countryDropdown = JSON.parse(countryDropdown.value);
              })
            })
          })
        })
        Preferences.keys().then(async (data) => {
          this.keys = data.keys;
        })

      
        this.mobileOfflineSyncApi().then(syncData => {
          const tableList = syncData.data;
          syncData.data.forEach((element: any, index) => {

            this.getObject(`${element.table_name}_table`).then(getdata => {
              const updatedDate = new Date(element.updated_at);
              const localdate = new Date(getdata.value.slice(1));

              if (updatedDate > localdate) {
                 this.presentLoading();
                  if(Capacitor.getPlatform() == 'web'){
                  this.router.navigate(['/home']);
                  }
                this.getObject('defaultLangCode').then((defLangCode: any) => {
                  this.deviceLangCode = defLangCode.value;

                  if (element.table_name === 'language_code') {

                    this.mobileLanguageApi().then((apidata) => {
                      this.setObject('languageDropdown', apidata.data).then(res => {
                        this.setObject(`${element.table_name}_table`, currentDate.toString());
                      });
                    })
                    if (index === tableList.length - 1) {
                      resolve(true);
                    }
                  } else if (element.table_name === 'country') {

                    this.mobileCountryApi(this.deviceLangCode).then((apidata) => {
                      this.setObject('countryDropdown', apidata.data).then(res => {
                        this.setObject(`${element.table_name}_table`, currentDate.toString());
                      })
                    })
                    if (index === tableList.length - 1) {
                      resolve(true);
                    }
                  }
                  else if (element.table_name === 'mobile_ui_text') {

                    this.mobileUiTextApi(this.deviceLangCode).then((apidata) => {

                      this.setObject('mobileUiText', apidata.data[0].text).then(res => {
                        this.setObject(`${element.table_name}_table`, currentDate.toString());
                        this.getObject('countryDropdown').then(countryDropdown => {
                          this.countryDropdown = JSON.parse(countryDropdown.value);
                        })
                      });
                    })
                    if (index === tableList.length - 1) {
                      resolve(true);
                    }
                  }
                  else if (element.table_name === 'banner') {

                    this.mobileBannerApi(this.deviceLangCode).then((apidata) => {
                      this.setObject('bannerList', apidata.data).then(res => {
                        this.setObject(`${element.table_name}_table`, currentDate.toString());
                      });
                    })
                    if (index === tableList.length - 1) {
                      resolve(true);
                    }
                  }
                  else if (element.table_name === 'config') {

                    this.mobileProdListApi(this.deviceLangCode).then((apidata) => {
                      this.setObject('listItem', apidata.data).then(res => {
                        this.setObject(`${element.table_name}_table`, currentDate.toString());
                      })
                    })
                    if (index === tableList.length - 1) {
                      resolve(true);
                    }
                  }
                  else if (element.table_name === 'supplementary') {
                    this.appInstall=true;  
                    this.driveProinfoApi = true;
                    this.mobileCommonPriorListApi = true;
                    this.tableList.push('supplementary');
                    if (index === tableList.length - 1) {
                      resolve(true);
                    }
                  } else if (element.table_name === 'product_type') {
                    this.appInstall=true;
                    this.mobileCommonPriorListApi = true;
                    this.tableList.push('product_type');

                    if (index === tableList.length - 1) {
                      resolve(true);
                    }
                  } else if (element.table_name === 'applications') {
                    this.appInstall=true;
                    this.mobileCommonPriorListApi = true;
                    this.tableList.push('applications');
                    if (index === tableList.length - 1) {
                      resolve(true);
                    }
                  } else if (element.table_name === 'items') {
                    this.appInstall=true;
                    this.mobileCommonPriorListApi = true;
                    this.tableList.push('items');

                    if (index === tableList.length - 1) {
                      resolve(true);
                    }

                  } else if (element.table_name === 'product_ranking') {
                    this.appInstall=true;
                    this.mobileCommonPriorListApi = true;
                    this.tableList.push('product_ranking');

                    if (index === tableList.length - 1) {

                      resolve(true);
                    }
                  }
                })
                 if (index === tableList.length - 1) {
                 this.dismissLoading();
                }
              }
              else {

                if (index === tableList.length - 1) {

                  resolve(true)
                }
              }
            })

          })

        })
      } catch (e) {
        console.error('catch rest api', e);

      }

    })
  }

  getMobileUiText() {
    return Promise.resolve(this.mobileUiText);

  }

  getCountryList() {
    return Promise.resolve(this.countryList);
  }async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait while syncing data from server..',
      duration: 7000,
    });
    await this.loading.present();
    this.activeLoaders.push(this.loading);
  }
  dismissLoading = async () => {
    await setTimeout(() => {    
      this.loadingController.dismiss();
    }, 5000);
  }
}
