import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomePage } from './home.page';
import {ListProductsComponent} from '../list/list-products/list-products.component';
import {ProductCategoryComponent } from '../list/product-category/product-category.component';
import {ProductDetailsComponent } from '../product-details/product-details.component';
import {IndustryPageComponent} from '../list/industry-page/industry-page.component';
import {SettingsComponent } from '../settings/settings.component';
import {LanguageSettingsComponent } from '../settings/language-settings/language-settings.component';
import {HeaderComponent } from '../header/header.component';
import {DownloadsComponent} from "../downloads/downloads.component";
import { WarrantyRegistrationComponent } from '../warranty-registration/warranty-registration.component';


const routes: Routes = [
  {
    path: '',
    component: HomePage,

},
{
  path: 'listProducts',
  component: ListProductsComponent
},
{
  path: 'listCategory',
 
  component: ProductCategoryComponent
},
{
  path: 'productDetails',
 
  component: ProductDetailsComponent
},
{
  path: 'settings',
 
  component: SettingsComponent
},
{
  path: 'header',
 
  component: HeaderComponent
},
{
  path: 'language',
 
  component: LanguageSettingsComponent
},
{
  path: 'IndustryPage',
 
  component: IndustryPageComponent
},{
  path: 'downloads',
 
  component: DownloadsComponent
},
{
 path: 'Warranty',
 component: WarrantyRegistrationComponent
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomePageRoutingModule {}
