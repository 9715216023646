import { Component, OnInit, Input } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';
import { Capacitor } from '@capacitor/core';
import { AnalyticsService } from 'src/app/analytics.services';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.scss'],
})
export class ProductCategoryComponent implements OnInit {
  @Input() previous: any;
  category;
  previouspage;
  current = 'listCategory';
  headerContent: any = {};
  productscat;
  title;
  input;
  mobileUiText;
  deviceLangCode;
  defaultLanguage;
  defaultCountry;
  imageInput;
  loading = this.loadingController.create({
    cssClass: 'my-custom-class',
    message: 'Please wait....'
  });

  constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute,
    private dataService: DataService, public loadingController: LoadingController,public analyticsService:AnalyticsService
    ) {
      this.presentLoading();
  }

  isMobile;
  Platform = Capacitor.getPlatform();
  ngOnInit() {
    if ((this.Platform === 'android') || (this.Platform === 'ios')) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
 
    this.dataLoad();
  }
  dataLoad() {
    this.dataService.getObject('defaultLangCode').then((defLangCode: any) => {
      this.dataService.deviceLangCode = defLangCode.value;
      this.deviceLangCode = this.dataService.deviceLangCode;

      this.dataService.getObject('defaultLanguage').then((defLang: any) => {
        this.dataService.defaultLanguage = defLang.value;
        this.defaultLanguage = this.dataService.defaultLanguage;
      })
      this.dataService.getObject('defaultCountry').then(defaultCountry => {
        this.defaultCountry = defaultCountry.value;
        this.dataService.defaultCountry = this.defaultCountry;

        this.dataService.getObject('listItem').then(prodList => {

          this.dataService.productList = JSON.parse(prodList.value);

        })
        this.dataService.getObject('defaultCountryCode').then(defcountryId => {
          this.dataService.defaultCountryId = JSON.parse(defcountryId.value);

        })

        this.dataService.getObject('mobileUiText').then(mobileText => {
          this.dataService.mobileUiText = JSON.parse(mobileText.value);
          this.mobileUiText = this.dataService.mobileUiText;
          this.dataService.settings = this.mobileUiText?.id_7;
          this.dataService.downloads = this.mobileUiText?.id_81;
          this.dataService.home = this.mobileUiText.id_33;
        })

        this.activatedRoute.queryParams.subscribe(dataip => {
          this.input = dataip;
          this.title = dataip.title;
          this.analyticsService.setScreenName("/"+dataip.title);
          this.imageInput = dataip.image;
          this.headerContent = {
            title: this.title, back: this.previouspage, currentPage: this.current,cat:true          };
          this.dataService.getObject(dataip.id).then(catlist => {
            if(catlist.value){
            this.productscat = JSON.parse(catlist.value);
              this.dismissLoading();
            }else{
              this.router.navigate(['']);
              this.dismissLoading();
            }
          })
        })
      })
    })
  }
 
  async presentLoading() {
    (await this.loading).present();
  }
  dismissLoading = async () => {
    (await this.loading).dismiss();
  }

  //Navigate To List Page
  list(name, id, type) {
    let href = this.router.url;

    const infoIp = this.input.type === "Type 1" ? false : true;
    this.router.navigate(['home/listProducts'], { queryParams: { id: id, title: name, type: this.input.type, prodId: this.input.id, info: infoIp,back:href} });

  }
}
