import { Component, OnInit } from '@angular/core';
import { constants } from '../app/constants';
import { AlertController, ToastController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { WarrantyRegistrationComponent } from './warranty-registration/warranty-registration.component';
// import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';


import { Network } from '@capacitor/network';
import { DataService } from './services/data.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Router } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';
import { SettingsComponent } from './settings/settings.component';
import { AnalyticsService } from './analytics.services';
import { Device } from '@capacitor/device';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  appInstall: boolean;
  locationAlert: any;
  ionVersionNumber: any;
  latest: any;
  state: string;
  deviceLang: string;
  deviceLangCode: string;
  mode: string;
  network: string;
  location: Object;
  locationStatus: string;
  message: string;
  settingsInput: string;
  networkInit: boolean;
  networkChange: boolean;
  toastCss: string;
  district: string;
  mobileUiText;
  languageDropdown;
  countryDropdown;
  defaultLanguage;
  defaultCountry;
  bannerList;
  loading;
  countryNAM:boolean;
  Platform = Capacitor.getPlatform();

  //enabled = this.analyticsService.analyticsEnabled;

  constructor(public alertController: AlertController,public diagnostic: Diagnostic,
    public toastController: ToastController, public data: DataService,
    private readonly router: Router, public modalController: ModalController,
    public loadingController: LoadingController,
    private analyticsService: AnalyticsService
  ) {
    //if (this.data.appInstall) {
      this.splash();
    //}
    this.mobileUiText = this.data.mobileUiText;
     this.data.getObject('defaultCountry').then(defaultCountry => {
      this.defaultCountry = defaultCountry.value;
      this.countryCheck();
    })
  }


  // setUser() {
  //   this.analyticsService.setUser();
  //  }
  
  //  setProperty() {
  //    this.analyticsService.setProperty();
  //  }
  
  //  logEvent() {
  //    this.analyticsService.logEvent();
  //  }
  
  
  //  toggleDataCollection() {
  //    this.analyticsService.toggleAnalytics();
  //    this.enabled = this.analyticsService.analyticsEnabled;
  //  }
  ngOnInit() {
     if ((this.Platform === 'android') || (this.Platform === 'ios')) {
      this.data.isMobile = true;
          
    }else {
      this.data.isMobile = false;     
        
    }
    if (this.deviceLang) {
      this.location = ({ 'device_language': this.deviceLang });
    }

    // To get initial network status whether available or not       
    Network.getStatus().then(status => {
      if (status) {
        this.networkInit = status.connected;
        this.data.networkStatus = this.networkInit;
      }
      if (!this.networkInit) {
        this.presentToast(this.networkInit);
      }
    });

    // check for changes in network status whether available or not
    Network.addListener('networkStatusChange', status => {

      if (status) {
        this.networkChange = status.connected;
      }
      if (this.networkInit !== this.networkChange) {
        this.networkInit = this.networkChange;
        this.data.networkStatus = this.networkInit;
        this.presentToast(this.networkInit);

      }
    })
     this.data.getEmittedcountryName().subscribe(countryName => {
      this.defaultCountry = countryName;
      this.countryCheck();
    });
  }

  async splash() {
    try {
      if(Capacitor.getPlatform() === 'android'){
      await StatusBar.setStyle({ style: Style.Dark });
      await StatusBar.setBackgroundColor({ color: '#e2000f' });
      }

      const deviceId = await Device.getId();
      await SplashScreen.hide();
      // await (await FirebaseCrashlytics.isEnabled()).enabled;
      // await FirebaseCrashlytics.addLogMessage({
      //   message: 'Test'
      // });
      // await FirebaseCrashlytics.setContext({
      //   key: 'page',
      //   value: 'home',
      //   type: 'string'
      // });
      // await FirebaseCrashlytics.addLogMessage({
      //   message: 'Test'
      // });
      // await FirebaseCrashlytics.setUserId({
      //   userId: deviceId.uuid
      // });
    } catch {
      // StatusBar only available on mobile devices// StatusBar.setBackgroundColor Only available on Android
    }
  }

  async countryCheck()
  {
    console.log("countrycgheck" ,this.defaultCountry )
this.countryNAM = false;
if (this.defaultCountry === "NAM")
{
  this.countryNAM = true;
}
  }

  // Toast Notification for network status  
  async presentToast(network) {
    const toast = await this.toastController.create({
      message: network ? this.mobileUiText.id_34 : this.mobileUiText.id_35,
      duration: 2000,
      cssClass: network ? constants.isNetworkOnlineCss : constants.isNetworkOfflineCss,

    });
    toast.present();
  }
  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: 5000,
    });
    await this.loading.present();
  }
   async registerClick() {
    if(this.data.isMobile)
    {
      const modal = await this.modalController.create({ component: WarrantyRegistrationComponent });
      await modal.present();
    }
    else{
      const url = 'home/Warranty';
      this.router.navigate([url]);
    }
  }
  async sidebarClick(id, name, type) {
    const val = id.toString();
    const url = 'home/listCategory';
    this.router.navigate([url], { queryParams: { title: name, type: type, id: val } });
  }
  async settings() {
    const modal = await this.modalController.create({ component: SettingsComponent });
    await modal.present();
  }
    async sidebarDownloadClick() {
    let PrevUrl=this.router.routerState.snapshot.url;
    const url = 'home/downloads';
    this.router.navigate([url], { queryParams: { title: 'Downloads', type: 'type', id: 'val',back:PrevUrl} });
  }
}
