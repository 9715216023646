import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PopoverController, AlertController, ModalController } from '@ionic/angular';

import { InfoPopoverComponent } from '../info-popover/info-popover.component';
import { OtpVerifyComponent } from '../otp-verify/otp-verify.component';
import { DataService } from '../services/data.service';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { filter } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() previous: any;
  category;
  islistProduct;
  islistCategory;
  previousnew;
  type;
  mailCountbadge;
  inputData;
  isXs$;
  platformInput;
  Platform = Capacitor.getPlatform();
  constructor(private readonly activatedRoute: ActivatedRoute, private readonly router: Router,
    public popoverController: PopoverController, public alertController: AlertController,
    public dataService: DataService, public modalController: ModalController,
    private readonly mediaObserver: MediaObserver,
  ) {
    this.activatedRoute.queryParams.subscribe(dataip => {
      this.inputData = dataip;
    })
    this.mailCountbadge = this.dataService.mailCount;
    this.dataService.getEmittedMailcount().subscribe(item => {
      this.mailCountbadge = this.dataService.mailCount;
    });
    this.dataService.getEmittedTitle().subscribe(item => {
      this.previous.title = item;
    });

    if ((this.Platform === 'android') || (this.Platform === 'ios')) {
        this.platformInput=true;
    }else{
      this.platformInput=false;
    }

  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(dataip => {
    });
    this.type = this.activatedRoute.snapshot.queryParams.type;
  }
  Header = 'Products-list';

  //Navigate To Home Directly...      
  home() {
    this.router.navigate(['']);
  }
  backPage($event) {
    
    if(this.inputData.back !== undefined){
     this.router.navigateByUrl(this.inputData.back);
    }else{
      this.router.navigate(['']);
    }
  }
  async infopopup(ev: any) {
    if (this.inputData.type === "Type 2") {
      const popover = await this.popoverController.create({
        component: InfoPopoverComponent,
        event: ev,
        componentProps: {
          infoPopup: 'infoPopup',
          industryName: this.previous.title,
          id: this.previous.id,
          itemid: this.previous.itemid,
        }
      });
      await popover.present();       
    } else {
      this.router.navigate(['home/productDetails'], { queryParams: { id: this.previous.id, info: true, title: this.previous.title, type: this.type } });
    }


  }
  async mailpopup(ev: any) {
    if (this.dataService.networkStatus) {
      const modal = await this.modalController.create({
        component: InfoPopoverComponent

      });
      await modal.present();
    } else {
      this.notifyController();
    }
  }
  async notifyController() {
    const alert = await this.alertController.create(
      {
        header: this.dataService.mobileUiText.id_47,
        message: this.dataService.mobileUiText.id_46,
        backdropDismiss: false,
        buttons: [{
          text: this.dataService.mobileUiText.id_48,
          role: this.dataService.mobileUiText.id_48,
        }],
      });
    await alert.present();
  
  }
  public media$ = this.mediaObserver.asObservable().pipe(filter(
    (change: MediaChange[]) =>change[0].mqAlias === 'xs')).subscribe(() => {
      if ((this.Platform === 'android') || (this.Platform === 'ios')) {
        this.isXs$ = true;
      } else {
        this.isXs$ = true;
      }
    });
      public media1$ = this.mediaObserver.asObservable().pipe(filter(
        (change: MediaChange[]) =>change[0].mqAlias !== 'xs')).subscribe(() => {
            this.isXs$ = false;
        });
             
  public getToolbarColor(): string {
    if((! this.isXs$) &&(!((this.Platform === 'android') || (this.Platform === 'ios')))) {
      return 'light';
    }else{
    
    return 'danfoss-red';
    }
  }
  public getButtonColor(): string {
    if((! this.isXs$) &&(!((this.Platform === 'android') || (this.Platform === 'ios')))) {
      return '';
    }else{    
      return 'light';
    }
  }
}
