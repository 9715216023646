import { Component, OnInit } from '@angular/core';
import { PopoverController, ToastController, NavParams } from '@ionic/angular';
import { DataService } from '../services/data.service';
import { Device } from '@capacitor/device';
import { constants } from '../../app/constants';

@Component({
  selector: 'app-otp-popover',
  templateUrl: './otp-popover.component.html',
  styleUrls: ['./otp-popover.component.scss'],
})
export class OtpPopoverComponent implements OnInit {
  otpInfo: any;
  OTP: string;
  isValid: boolean = true;
  mobileUiText: any;
  deviceId: any;
  timer: number = 60; // Total time for the timer
  timerInterval: any;
  isResendEnabled: boolean = true; // Initially disable the Resend OTP button
 isOTPInvalid: boolean = false; // New flag for invalid OTP
  constructor(
    private readonly popover: PopoverController,
    public dataService: DataService,
    public toastController: ToastController,
    public navParams: NavParams
  ) {}

  ngOnInit() {
    this.startTimer();
    this.deviceId = Device.getId();
    this.otpInfo = this.navParams.get('email');
    this.dataService.getObject('mobileUiText').then((mobileText) => {
      this.mobileUiText = JSON.parse(mobileText.value);
    });
  }

  startTimer() {
    this.isResendEnabled = true; // Disable the Resend OTP button
    this.timer = 60; // Reset the timer to 60 seconds
    clearInterval(this.timerInterval); // Clear any existing timer interval

    this.timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
        
        // Enable the "Resend OTP" button after 30 seconds
        if (this.timer <= 30) {
          this.isResendEnabled = false;
        }
      } else {
        clearInterval(this.timerInterval); // Clear the interval
      }
    }, 1000);
  }

  onOTPChange(event: any) {
    // Validate OTP input to be exactly 6 digits
    const otpValue = event.target.value;
    if (otpValue && otpValue.length === 6 && /^[0-9]+$/.test(otpValue)) {
      this.OTP = otpValue;
      this.isValid = false; // Enable the Send OTP button
      this.isOTPInvalid = false; // Valid OTP format
    } else {
      this.isValid = true; // Disable the Send OTP button
      this.isOTPInvalid = true; // Invalid OTP format
    }
  }

  async OTPSend() {
    const deviceId = await Device.getId();

    this.dataService.emailVerifyApi({
      email: this.otpInfo,
      device_uid: deviceId.identifier,
      otp: this.OTP,
      lang_code: this.dataService.deviceLangCode
    }).then(async (res) => {
      if (res.status) {
        this.popover.dismiss({ data: res });
      } else {
        const toast = await this.toastController.create({
          message: res.message,
          duration: 2000,
          cssClass: constants.isNetworkOfflineCss,
        });
        toast.present();
      }
    });
  }

  closePopover() {
    this.popover.dismiss();
  }

  async ReSendOTP() {
    const deviceId = await Device.getId();
    
    this.dataService.emailVerifyApi({
      email: this.otpInfo,
      device_uid: deviceId.identifier,
      lang_code: this.dataService.deviceLangCode
    }).then(() => {
      this.startTimer(); // Restart the timer after resending OTP
    });
  }
}
