import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { SettingsLicenseComponent } from '../settings-license/settings-license.component';
import { SettingsOpensourceComponent } from '../settings-opensource/settings-opensource.component';
@Component({
    selector: 'app-legal-information',
    templateUrl: './legal-information.component.html',
    styleUrls: ['./legal-information.component.scss'],
})
export class LegalInformationComponent {

    @Input() value: any;
    @Input() id: any;

    backbtn;
    loading = this.loadingController.create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: 5000,
    });
    constructor(private readonly router: Router, public modalController: ModalController,
        public dataService: DataService, public alertController: AlertController
        , public loadingController: LoadingController) {
        this.backbtn = App.addListener('backButton', data => {
            this.GoBack();
        })

    }
    public GoBack() {
        this.modalController.dismiss();
    }
    async presentLoading() {
        (await this.loading).present();
      }
    ngOnInit() {

    }

    async showLicensePage(){
        const language = true;
        const modal = await this.modalController.create({
          component: SettingsLicenseComponent, id: "language", componentProps: {
            value: language,
    
          }
        });
        await modal.present();
    }

    async showOpenSourcePage(){
        const language = true;
        const modal = await this.modalController.create({
          component: SettingsOpensourceComponent, id: "language", componentProps: {
            value: language,
    
          }
        });
        await modal.present();
    }

}