import { NgModule,APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';


import { AppVersion } from '@ionic-native/app-version/ngx';
import { InAppBrowser  } from '@ionic-native/in-app-browser/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { DeltaUiModule } from '@drives/delta-ui';
import { DeltaThemeModule } from '@drives/delta-theme';
import {HomePageModule} from './home/home.module';
import {HomePage } from './home/home.page';
import { CommonModule } from '@angular/common';
import {InfoPopoverComponent} from './info-popover/info-popover.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { FileTransfer} from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { AppInitService} from './app.initializer';
import { DataService} from './services/data.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { OtpVerifyComponent } from './otp-verify/otp-verify.component';
import { OtpPopoverComponent } from './otp-popover/otp-popover.component';
import{PreviewAnyFile} from '@ionic-native/preview-any-file/ngx';

export function initializeAppFactory(appInitService: AppInitService) {
  return (): Promise<any> => { 
    return appInitService.Init();
  }
}
@NgModule({
  declarations: [AppComponent,OtpPopoverComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,HttpClientModule, IonicStorageModule.forRoot()
    ,DeltaThemeModule.forRoot(),DeltaUiModule,HomePageModule, CommonModule,FormsModule,ReactiveFormsModule, 
    ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: environment.production,
  // Register the ServiceWorker as soon as the app is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
})],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AppVersion,InAppBrowser,NativeGeocoder,PreviewAnyFile,File,
    Diagnostic,  InfoPopoverComponent,OtpVerifyComponent, HomePage,FileTransfer,File,AppInitService, OtpPopoverComponent,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppInitService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(public data:DataService){}
}
