export const constants = {

    isNetworkOnlineCss: 'toast-scheme-active',
    isNetworkOfflineCss: 'toast-scheme',
    isLocationGranted: 'GRANTED',
    isLocationGRANTED_WHEN_IN_USE: 'GRANTED_WHEN_IN_USE',
    isLocationAuthorized_when_in_use: 'authorized_when_in_use',
    isDeniedAlways: 'DENIED_ALWAYS',
    appUpdateUrlAndroid: 'https://play.google.com/store/apps/details?id=com.danfoss.DrivePortfolio',
    appUpdateUrlIos: 'https://apps.apple.com/in/app/mydrive-portfolio/id1091504327',
}
