import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
@Component({
    selector: 'app-settings-license',
    templateUrl: './settings-license.component.html',
    styleUrls: ['./settings-license.component.scss'],
})
export class SettingsLicenseComponent {

    @Input() value: any;
    @Input() id: any;

    backbtn;
    loading = this.loadingController.create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: 5000,
    });
    constructor(private readonly router: Router, public modalController: ModalController,
        public dataService: DataService, public alertController: AlertController
        , public loadingController: LoadingController) {
        this.backbtn = App.addListener('backButton', data => {
            this.GoBack();
        })

    }
    public GoBack() {
        this.modalController.dismiss();
    }
    async presentLoading() {
        (await this.loading).present();
      }
    ngOnInit() {

    }


}