import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HomePage } from './home.page';
import { HomePageRoutingModule } from './home-routing.module';
import { MessageComponentModule } from '../message/message.module';
import { ListProductsComponent } from '../list/list-products/list-products.component';
import { ProductCategoryComponent } from '../list/product-category/product-category.component';
import { ProductDetailsComponent } from '../product-details/product-details.component';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { SettingsComponent } from '../settings/settings.component';
import { LanguageSettingsComponent } from '../settings/language-settings/language-settings.component';
import { HeaderComponent } from '../header/header.component';
import { InfoPopoverComponent } from '../info-popover/info-popover.component';
import { IndustryPageComponent } from '../list/industry-page/industry-page.component';
import { DeltaUiModule } from '@drives/delta-ui';
import { LicenseComponent } from '../license-agreement/license.component';
import { LegalInformationComponent } from '../settings/legal-information/legal-information.component';
import { SettingsOpensourceComponent } from '../settings/settings-opensource/settings-opensource.component';
import { SettingsLicenseComponent } from '../settings/settings-license/settings-license.component';
import { DownloadsComponent } from '../downloads/downloads.component';
import { WarrantyRegistrationComponent } from '../warranty-registration/warranty-registration.component';

@NgModule({

  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MessageComponentModule,
    HomePageRoutingModule,
    DeltaUiModule,
    ReactiveFormsModule
  ],
  declarations: [HomePage, ListProductsComponent, ProductCategoryComponent, ProductDetailsComponent,
    SettingsComponent, HeaderComponent, InfoPopoverComponent,
    LanguageSettingsComponent, IndustryPageComponent, ImageViewerComponent,LicenseComponent, LegalInformationComponent, 
    SettingsOpensourceComponent,SettingsLicenseComponent,DownloadsComponent,WarrantyRegistrationComponent
  ],


})
export class HomePageModule {
  constructor() {

  }


}
