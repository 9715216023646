import { DataService } from '../services/data.service';
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InAppBrowser, InAppBrowserEvent, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { AlertController,LoadingController,ToastController } from '@ionic/angular';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { filter } from 'rxjs/operators';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { constants } from '../constants';
import { saveAs } from 'file-saver';
import { AnalyticsService } from '../analytics.services';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
  @Input() previous: any;
  previouspage;
  category;
  headerContent: any = {};
  current = 'ProductDetails';

  products;
  products01;
  inputarray;
  Header = "Details Page";
  item = { icon: "assets/VLTMicroDrive.jpg" }
  isDescription = true;
  isFeatures = false;
  isSpecification = false;
  isSelected = false;
  isMailContent = false;
  mailClicked=false;
  mailContent = {
    "product_id": "", "product_name": "", "lang_code": "",
    "supplemenatry_list": [],
    "next_step": []
  };
  mobileUiText: any = {};
  infoDetail;
  inputQuery: any = {};
  content: any = [];
  information: any = [];
  supplemenatryList;
  isSupplemenatryListEnable = true;
  hideme1 = {};
  nextStep;
  drivePro = false;
  isNextStepEnable = true;
  deviceLangCode;
  defaultLanguage;
  productImage = "";
  isproductImage = false;
  isXs$;
  isMobile;
  loading: any;
  Platform = Capacitor.getPlatform();
  constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute,
    private dataService: DataService, public alertController: AlertController,
    private readonly inAppBrowser: InAppBrowser, private readonly mediaObserver: MediaObserver,
    public fTransfer: FileTransfer, public file: File,public loadingController: LoadingController,public toastController: ToastController,
    public analyticsService: AnalyticsService) {

    this.dataLoad();
    this.hideme1 = {};
    Filesystem.checkPermissions().then(chkper=>{
    if(chkper.publicStorage !== 'granted'){
      Filesystem.requestPermissions();
    }
  })
  }
  // Toast Notification for network status  
  async presentToast() {
    const toast = await this.toastController.create({
      message: "File Downloaded Successfully",
      duration: 2000,
      cssClass: constants.isNetworkOnlineCss,

    });
    toast.present();
  }
 async presentLoading() {

  this.loading = await this.loadingController.create({
    cssClass: 'my-custom-class',
    message: 'Please wait...',
    duration: null,
  });
  await this.loading.present();
 }

async internettoast()
{
  const toast = await this.toastController.create({
    message: this.mobileUiText.id_46,
    duration: 2000,
    cssClass: constants.isNetworkOfflineCss,  
  });
  toast.present(); 
}
  async dismiss() {
    await this.loadingController.dismiss();
  }

  fileTransfer: FileTransferObject = this.fTransfer.create();

  ngOnInit() {
    if ((this.Platform === 'android') || (this.Platform === 'ios')) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

  }

  dataLoad() {
    this.dataService.getObject('defaultLangCode').then((defaultLangCode: any) => {
      this.dataService.deviceLangCode = defaultLangCode.value;
      this.deviceLangCode = this.dataService.deviceLangCode;
      this.dataService.getObject('defaultLanguage').then((defaultLang: any) => {
        this.dataService.defaultLanguage = defaultLang.value;
        this.defaultLanguage = this.dataService.defaultLanguage;
      })
      this.dataService.getObject('mobileUiText').then(mobileUIText => {
        this.dataService.mobileUiText = JSON.parse(mobileUIText.value);
        this.mobileUiText = this.dataService.mobileUiText;
        this.dataService.home = this.mobileUiText.id_33;
        this.dataService.settings = this.mobileUiText?.id_7;
        this.dataService.downloads = this.mobileUiText?.id_81;
      })
      this.dataService.getObject('listItem').then(productList => {
        this.dataService.productList = JSON.parse(productList.value);
      })

      this.activatedRoute.queryParams.subscribe(dataip => {
        this.mailContent = {
          "product_id": "", "product_name": "", "lang_code": "",
          "supplemenatry_list": [],
          "next_step": []
        };
        this.inputQuery = dataip;

        if (this.inputQuery.type === "Type 3") {
          this.drivePro = this.inputQuery.title;

        }
        this.headerContent = {
          title: this.inputQuery.title, currentPage: this.current,PreviousPage:this.inputQuery.back,cat:false
        };

        this.dataService.getObject(this.inputQuery.catid).then(details => {
           if(details.value){
          const contentip = JSON.parse(details.value);
          const contentarray = contentip.filter(item => {
            return item.unique_id === Number(this.inputQuery.id)
          })
          this.content = contentarray[0];
          this.productImage = this.content.image;
          this.isproductImage = true;
          this.analyticsService.setScreenName("/"+this.content.name);
          const information = this.content.information;
          this.supplemenatryList = this.content.supplemenatry_list;
          this.supplemenatryList = this.supplemenatryList.map(v => ({ ...v, isChecked: false }));
           this.supplemenatryList.map((v) =>{       
          if(((v.external_url).substr(v.external_url.length - 4) == ".pdf")||((v.external_url).startsWith("https://danfoss.ipapercms.dk/"))){
            v.isDisabled = false;
          }else{
          v.isDisabled = true;
           }
            if((v.external_url).startsWith("https://assets.danfoss.com/") && (!this.isMobile)){
              v.isDisabled = true;
            }
          });
          
          if(this.supplemenatryList.length == 0) {
            this.isSupplemenatryListEnable = false;
          } else {            
            this.isSupplemenatryListEnable = true;
          }
          this.information = information;
          this.nextStep = this.content.next_step;
          this.nextStep = this.nextStep.map(v => ({ ...v, isChecked: false }));
          this.nextStep.map((v) =>{       
            if(((v.name).substr(v.name.length - 4) == ".pdf")||((v.name).startsWith("https://danfoss.ipapercms.dk/"))){
              v.isDisabled = false;
            }else{
            v.isDisabled = true;
           }   
          if( ((v.name).startsWith("https://assets.danfoss.com/") )&& (!this.isMobile)){
              v.isDisabled = true;
           }   
         });
          let validSteps:any = {};
          if(this.nextStep.length) {
            this.nextStep.forEach((stepInfo) => {
              if(stepInfo.name){
                stepInfo['isValid'] = true;
              } else {                
                stepInfo['isValid'] = false;
              }              
              validSteps[stepInfo.isValid] = (validSteps[stepInfo.isValid] || 0) + 1;
            });
            if(Object.keys(validSteps).length === 1) {
              if(validSteps.false) {
                this.isNextStepEnable = false;
              }
            }
          }

          this.information.forEach(e => {
            if (this.information.indexOf(e) === 0) {
              this.hideme1[e.name] = true;
            } else {
              this.hideme1[e.name] = false;
            }
          });
        }else{
          this.router.navigate(['']);
        }
        })
        //  }
      })
    })
  }

  public media$ = this.mediaObserver.asObservable().pipe(filter(
    (change: MediaChange[]) => change[0].mqAlias === 'xs')).subscribe(() => {

      this.isXs$ = true;
    });
 

  view(externalUrl) {
    let that = this;
    const options: InAppBrowserOptions = {
      location: 'yes',
      toolbarcolor: '#B6000F',
      navigationbuttoncolor: 'white',
      closebuttoncaption: 'close',
      closebuttoncolor: 'white',
      hideurlbar: 'yes',
      toolbar: 'yes',
      useWideViewPort: 'yes',
      fullscreen: 'yes'
    }      
    if(this.dataService.networkStatus)
  {
   if ((this.Platform === 'android') || (this.Platform === 'ios')) 
   {
      let checkPDF = externalUrl.split(/[#?]/)[0].split('.').pop().trim();
      if(checkPDF.toLowerCase() === 'pdf') {
        Browser.open({ url: externalUrl});
        } else {
          this.inAppBrowser.create(externalUrl, '_blank', options);
        }
      } else {
        this.inAppBrowser.create(externalUrl, '_blank', options);
      }
    }
   else{     
        this.internettoast();
    }  
  }

  //Add to Mail
  AddToMail() {
    const nxtstp = this.nextStep.filter(
      item => {
        return item.isChecked === true;
      });
    const suptry = this.supplemenatryList.filter(
      item => {
        return item.isChecked;
      });

    if (this.dataService.mailCount < 3) {
      if ((this.dataService.mailContentArray.find(({ product_id }) => product_id === this.inputQuery.id)) === undefined) {
        this.mailContent.product_id = this.inputQuery.id;
        this.mailContent.product_name = this.content.name;

        this.mailContent.lang_code = this.dataService.deviceLangCode;
        if (nxtstp === 0 && suptry === 0) {
          this.mailContent.supplemenatry_list = this.supplemenatryList;
          this.mailContent.next_step = this.nextStep;


        } else {
          this.mailContent.supplemenatry_list = this.supplemenatryList.filter(
            item => { return item.isChecked });
          this.mailContent.next_step = this.nextStep.filter(
            item => { return item.isChecked });

        }
        this.dataService.mailContentArray.push(this.mailContent);

        this.dataService.setMailCount(1);


      } else {

        this.notifyController(this.mobileUiText.id_16);
      }

    }
    else {
      this.notifyController(this.mobileUiText.id_17);

    }

  }

  showInfo(item) {
    Object.keys(this.hideme1).forEach(h => {
      if (h === item.name) {
        this.hideme1[item.name] = !this.hideme1[item.name];
      } else {
        this.hideme1[h] = false;
      }

    });

  }
  async notifyController(uiText) {
    const alert = await this.alertController.create(
      {
        header: this.mobileUiText.id_21,
        message: uiText,
        backdropDismiss: false,
        buttons: [{
          text: this.mobileUiText.id_48,
          role: this.mobileUiText.id_48,
        }],
      });
    await alert.present();

  }
  nexStepCBAction(item) {
    item.isChecked = !item.isChecked;
  }
  suppCBAction(item) {
    item.isChecked = !item.isChecked;
    this.mailClicked=!this.mailClicked;
  }
  Download(item){
    let fileLocation;
    
  if(this.dataService.networkStatus)
  {
      if (this.Platform === 'android') {
        fileLocation=this.file.externalDataDirectory
      }else if (this.Platform === 'ios') {
        fileLocation=this.file.documentsDirectory
      }
    
    let checkPDF = (item.external_url).substr(item.external_url.length - 4)
    let fileName =(item.name).replace(/[^\w]/g,'');
    if(checkPDF.toLowerCase() === '.pdf') {
       
      if(this.isMobile){
        this.presentLoading();
        this.fileTransfer.download(encodeURI(item.external_url),`${fileLocation}${"PDF"}/${fileName+".pdf"}`).then((entry) => {
          if (entry) {
             this.dismiss();
            this.presentToast();
          }}).catch(err=>{
            console.log("error download==",err);
            this.dismiss();
          })
      }else{
        try{
          this.presentLoading();
        this.dataService.DownloadPdfFile(item.external_url).subscribe((data) => {        
        let file = new Blob([data], { type: 'application/pdf' });
        saveAs(file,fileName);         
         this.dismiss();
           this.presentToast();
        })
      }
      catch(exception){
        console.log("uploaded PDF error = ",exception);
        this.dismiss();
      }
      }
      }else if((item.external_url).startsWith("https://danfoss.ipapercms.dk/")){
        let Url = (item.external_url).substr(0, ((item.external_url).lastIndexOf("/"))+1)
          if(Url.endsWith('#/')){
          Url=Url.slice(0,-2);
        }
        let finalUrl =Url+"GetPDF.ashx";
        if(this.isMobile){
           this.presentLoading();
        this.fileTransfer.download(encodeURI(Url+"GetPDF.ashx"),`${fileLocation}${"PDF"}/${fileName+".pdf"}`).then((entry) => {
          if (entry) {
           console.log("file downloaded==entry==",entry);
            this.dismiss();
            this.presentToast();
          }}).catch(err=>{
            console.log("error download==",err);
            this.dismiss();
          });
        }else{
          try{
             window.open(finalUrl);
            this.presentToast();
          }      
          catch(exception){
            console.log("uploaded PDF error = ",exception);
            this.dismiss();
          }
        }
      }
  }    
    else{
      this.internettoast();
    }
  }
}